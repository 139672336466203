import { createEffect, FlowComponent, JSX, onCleanup, Show } from "solid-js";
import { createStore } from "solid-js/store";
import { Motion, Presence } from "solid-motionone";
import overlayApi from "solid-overlays";

import FeatureStore from "@repo/utils-solid/FeatureStore";

import ContainerButton from "../buttons/ContainerButton";
import FeaturesProvider from "../context/FeaturesProvider";
import { ErrorProvider, useError } from "../error/ErrorProvider";
import Alert from "../info/Alert";

import { invalidate } from "./signals";

import "solid-overlays/index.css";
import "./GlobalRoot.scss";

declare global {
  function assert(value: any, message?: string): asserts value;
}

globalThis.assert = (value, message = "Assertion failed") => {
  if (!value) throw new Error(message);
};

FeatureStore.configure({
  useCaptureError: () => {
    const error = useError();
    return (e) => {
      if (!(typeof e === "string" || e instanceof Error))
        throw new Error(
          `Attempted to capture error with unsupported type: ${e}`,
        );
      return error.capture(e);
    };
  },
});

declare namespace GlobalRoot {
  type Props = {
    OverlaysProvider: ReturnType<
      ReturnType<typeof overlayApi<{}>>["create"]
    >["OverlaysProvider"];
    featureProviders: FlowComponent[];
    children: JSX.Element;
  };
}

function GlobalRoot(props: GlobalRoot.Props) {
  const [error, setError] = createStore({ message: "", show: false });

  createEffect(() => {
    ({ ...error });

    const t = setTimeout(() => {
      setError("show", false);
    }, 5000);

    onCleanup(() => {
      clearTimeout(t);
    });
  });

  return (
    <div id="global-root">
      {(() => (
        invalidate.listen(),
        (
          <ErrorProvider
            onError={(error) => {
              const message = typeof error === "string" ? error : error.message;
              setError({ message, show: !!message });
            }}
          >
            <props.OverlaysProvider>
              {({ renderOverlays }) => (
                <FeaturesProvider featureProviders={props.featureProviders}>
                  {props.children}
                  {renderOverlays()}
                </FeaturesProvider>
              )}
            </props.OverlaysProvider>
            <Presence>
              <Show when={error.show}>
                <Motion.div
                  id="error"
                  class="center-child"
                  initial={{ opacity: 0, y: 24 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 24 }}
                >
                  <ContainerButton
                    class="fit-content"
                    onClick={() => {
                      setError("show", false);
                    }}
                  >
                    <Alert type="error" class="p-2">
                      {error.message}
                    </Alert>
                  </ContainerButton>
                </Motion.div>
              </Show>
            </Presence>
          </ErrorProvider>
        )
      ))()}
    </div>
  );
}

export default GlobalRoot;
