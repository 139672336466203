// @refresh reload
import "solid-devtools/setup";

import Log from "@repo/utils/Log";
import Platform from "@repo/utils-client/Platform";
Log.enabled =
  Platform.isClient &&
  (import.meta.env.APP_ENV !== "production" ||
    new URLSearchParams(window.location.search).has("DEBUG"));

import { onMount, Suspense } from "solid-js";
import { MetaProvider, Title } from "@solidjs/meta";
import { Router } from "@solidjs/router";
import { FileRoutes } from "@solidjs/start/router";

import { useError } from "@repo/ui/error/ErrorProvider";
import GoogleSignIn from "@repo/ui/external/GoogleSignIn";
import GlobalRoot from "@repo/ui/global/GlobalRoot";
import UString from "@repo/utils/UString";
import AppRouter from "@repo/utils-solid/AppRouter";

import { useAdmin } from "./state/features/admin";
import { OverlaysProvider } from "./overlays";
import { featureProviders } from "./state";

export default function App() {
  return (
    <Router
      root={(props) => {
        AppRouter.register();

        return (
          <GoogleSignIn.Provider
            clientId={import.meta.env.ADMIN_GOOGLE_OAUTH_CLIENT_ID}
            apiDomain={UString.afterPrefix(
              new URL(import.meta.env.API_ORIGIN).hostname,
              "api.",
            )}
          >
            <MetaProvider>
              <Title>Admin | Kikoff</Title>
              <GlobalRoot
                OverlaysProvider={OverlaysProvider}
                featureProviders={featureProviders}
              >
                {(() => {
                  const [, setAdmin] = useAdmin();
                  const error = useError();

                  GoogleSignIn.callback = ({ credential }) => {
                    setAdmin.googleSsoCallback(credential).catch((e) => {
                      error.capture(e);
                    });
                  };

                  onMount(() => {
                    setAdmin.fetch().catch((e) => {
                      error.capture(e.message);
                    });
                  });

                  return <Suspense>{props.children}</Suspense>;
                })()}
              </GlobalRoot>
            </MetaProvider>
          </GoogleSignIn.Provider>
        );
      }}
    >
      <FileRoutes />
    </Router>
  );
}
